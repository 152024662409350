<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    append-to-body
    @open="openDialog"
    :close-on-click-modal="false"
    top="25vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>设置查看时间</span>
    </div>
    <el-row>
    <el-date-picker
      v-model="dataPicker"
      type="datetime"
      value-format="yyyy-MM-dd HH:mm:ss"
      placeholder="选择日期时间">
    </el-date-picker>
    </el-row>
    <el-row style="margin-top: 15px;">
    <el-button type="primary" @click="setMinHistoryTime"> 设置</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  import { setMinHistoryTime } from '@/api/senate/class'

  export default {
    name:"PreviewAccessory",
    props: {
      show: {
        default: false,
        type: Boolean
      },
      taskId: {
        default: '',
        type: String
      },
      studentData: {
        default: () => {
          return {}
        },
        type: Array
      }
    },
    data () {
      return {
        dataPicker:"",
        userIds:[],
      }
    },
    methods: {
      openDialog() {
        this.dataPicker = ''
        this.userIds = []
        this.studentData.forEach((item)=> {
          this.userIds.push(item.userId)
        })
      },
      async setMinHistoryTime() {
        if(this.dataPicker === '') {
         window.$msg("请先选择时间",2)
          return  false
        }
        const response = await  setMinHistoryTime({
          minHistoryTime: this.dataPicker,
          userIds: this.userIds,
          taskId:this.taskId,
        })

        if(response && response.state === 'success') {
          window.$msg('设置时间成功')
          this.$emit('listMinHistoryTime')
          this.$emit('close')
        }
      },
      close () {
        this.$emit('close')
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/dialog.scss";
</style>
