var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": "",
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("允许查看记录时间")])]
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "15px" },
          attrs: { type: "primary" },
          on: { click: _vm.openSetDialog },
        },
        [_vm._v("设置查看时间")]
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "15px" },
          attrs: { type: "primary" },
          on: { click: _vm.openPreviewTime },
        },
        [_vm._v("不限查看时间")]
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { label: "姓名", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "学号", align: "center", prop: "loginName" },
          }),
          _c("el-table-column", {
            attrs: { label: "可查看时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.minHistoryTime === "0001-01-01 00:00:00.0"
                      ? _c("span", [_vm._v("全时段查看")])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.minHistoryTime.substring(
                                0,
                                scope.row.minHistoryTime.length - 2
                              )
                            )
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("set-history-time-detail", {
        attrs: {
          taskId: _vm.taskId,
          lessonId: _vm.lessonId,
          show: _vm.sethistoryDetail,
          studentData: _vm.multipleSelection,
        },
        on: {
          listMinHistoryTime: _vm.listMinHistoryTime,
          close: function ($event) {
            _vm.sethistoryDetail = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }