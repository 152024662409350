<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>允许查看记录时间</span>
    </div>
    <el-button style="margin-bottom: 15px;" type="primary" @click="openSetDialog">设置查看时间</el-button>
    <el-button style="margin-bottom: 15px;" type="primary" @click="openPreviewTime">不限查看时间</el-button>
    <el-table :data="dataList"
              border
              fit
              highlight-current-row
              @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column label="姓名" align="center" prop="name"></el-table-column>
      <el-table-column label="学号" align="center" prop="loginName"></el-table-column>

      <el-table-column label="可查看时间" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.minHistoryTime === '0001-01-01 00:00:00.0'">全时段查看</span>
          <span v-else >{{ scope.row.minHistoryTime.substring(0,scope.row.minHistoryTime.length-2) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <set-history-time-detail
      :taskId="taskId"
      :lessonId="lessonId"
      :show="sethistoryDetail"
      :studentData="multipleSelection"
      @listMinHistoryTime = 'listMinHistoryTime'
      @close="sethistoryDetail = false"
    ></set-history-time-detail>
  </el-dialog>
</template>

<script>
  import {listMinHistoryTime,setMinHistoryTime } from '@/api/senate/class'
  import setHistoryTimeDetail from '@/components/senate/set-historytimedetail'

  export default {
    name:"PreviewAccessory",
    props: {
      show: {
        default: false,
        type: Boolean
      },
      taskId: {
        default: '',
        type: String
      },
      lessonId: {
        default: '',
        type: String
      },
    },
    components:{setHistoryTimeDetail},
    data () {
      return {
        dataList:[],
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 30, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        multipleSelection:[],
        userIds:[],
        sethistoryDetail:false,
      }
    },
    methods: {
      openSetDialog() {
        if(this.multipleSelection.length === 0) {
          window.$msg('请先选择选择学员',2)
          return false
        }
        this.sethistoryDetail =  true
      },
      async openPreviewTime() {
        if(this.multipleSelection.length === 0) {
          window.$msg('请先选择选择学员',2)
          return false
        }
        this.multipleSelection.forEach((item)=> {
          this.userIds.push(item.userId)
        })
        const response = await  setMinHistoryTime({
            minHistoryTime: '0001-01-01 00:00:00',
            userIds: this.userIds,
            taskId:this.taskId,
          })

          if(response && response.state === 'success') {
            window.$msg('设置时间成功')
            this.listMinHistoryTime()
          }
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 分页
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.listMinHistoryTime()
      },
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.listMinHistoryTime()
      },
      openDialog () {
        this.listMinHistoryTime()
      },

      async listMinHistoryTime() {
        const response = await  listMinHistoryTime({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          taskId:this.taskId,
          lessonId:this.lessonId
        })

        if(response && response.state === 'success') {
          this.dataList = response.body.list
          this.pagination.total = response.body.total
        }
      },
      close () {
        this.$emit('close')
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/dialog.scss";
</style>
