var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": "",
        "close-on-click-modal": false,
        top: "25vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("设置查看时间")])]
      ),
      _c(
        "el-row",
        [
          _c("el-date-picker", {
            attrs: {
              type: "datetime",
              "value-format": "yyyy-MM-dd HH:mm:ss",
              placeholder: "选择日期时间",
            },
            model: {
              value: _vm.dataPicker,
              callback: function ($$v) {
                _vm.dataPicker = $$v
              },
              expression: "dataPicker",
            },
          }),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.setMinHistoryTime },
            },
            [_vm._v(" 设置")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }